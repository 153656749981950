import * as React from "react"
import PropTypes from "prop-types"
import Logo from "./logo.inline.svg"

const Header = ({ siteTitle }) => (
  <header
    style={{
      width: "100%",
      display: "flex",
      flexDirection: "row-reverse"
    }}
  >
    <Logo style={{
      width: "100px",
      height: "40px"
    }}/>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
