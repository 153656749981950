import * as React from 'react'
import { useEffect } from 'react';
import { navigate } from "gatsby"
import Layout from "../components/layout"

const NotFound = () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return (
    <Layout>
        <h1 className="main-heading">404 - Not Found</h1>
    </Layout>
  );
};

export default NotFound